(function (FietsZaken) {

    FietsZaken.Util = {

        confirmClick: function (e) {
            e.preventDefault();

            var elem     = jQuery(this),
                target   = elem.attr('href'),
                method   = (elem.data('confirm')) ? elem.data('confirm') : 'GET';

            swal({
                type:                (elem.data('type') ? elem.data('type') : 'warning'),
                text:                (elem.data('msg') ? elem.data('msg') : null),
                showCancelButton:    true,
                cancelButtonText:    window.i18n.btn_cancel,
                confirmButtonText:   window.i18n.btn_confirm,
                confirmButtonColor:  '#DD6B55',
                title:               window.i18n.msg_confirm_title,
                closeOnConfirm:      false,
                showLoaderOnConfirm: true
            }, function () {
                if (jQuery.inArray(method.toUpperCase(), ['DELETE', 'POST']) !== -1) {
                    jQuery('body').append(jQuery(
                        '<form id="_confirm" action="' + target + '" method="POST" style="display:none;">' +
                            '<input type="hidden" name="_token" value="' + window.CSRF + '" />' +
                            '<input type="hidden" name="_method" value="' + method.toUpperCase() + '" />' +
                        '</form>'
                    ));

                    document.forms['_confirm'].submit();
                } else {
                    window.location.href = target;
                }
            });
        },

        getAPIUrl: function (url) {
            return window.API.URL + url;
        },

        isScrolledIntoView: function (elem) {
            var docViewTop    = jQuery(window).scrollTop();
            var docViewBottom = docViewTop + jQuery(window).height();

            var elemTop    = jQuery(elem).offset().top;
            var elemBottom = elemTop + jQuery(elem).height();

            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        },

        slugify: function (string) {
            return string.toLowerCase()
                .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
                .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single -
                .replace(/^-+|-+$/g, ''); // remove leading, trailing -
        },

        getAddressData: function (address) {
             return jQuery.get('//maps.google.com/maps/api/geocode/json', {
                address: encodeURI(address),
                sensor: false
            });
        }

    };

})(window.FietsZaken);
