(function () {

    // Model: MediaItem
    var MediaItem = function (item) {
        this.id   = item.id;
        this.url  = item.url;
        this.name = item.name;
        this.type = item.type;
        this.size = item.size;
        this.icon = 'fa-file-o';

        if (!item.preview || item.preview === null) {
            if (this.type === "image") {
                this.icon = 'fa-image-o';
            } else if (this.type === 'document') {
                if (item.extension === 'pdf') {
                    this.icon = 'fa-file-pdf-o';
                } else if (jQuery.inArray(item.extension, ['doc', 'dot', 'docx'])) {
                    this.icon = 'fa-file-word-o';
                } else if (jQuery.inArray(item.extension, ['xls', 'xlt', 'xla', 'xlsx'])) {
                    this.icon = 'fa-file-excel-o';
                } else if (jQuery.inArray(item.extension, ['pot', 'ppa', 'pps', 'pws', 'ppt', 'pptm', 'pptx'])) {
                    this.icon = 'fa-file-powerpoint-o';
                } else {
                    this.icon = 'fa-file-text-o';
                }
            } else if (this.type === "audio") {
                this.icon = 'fa-file-audio-o';
            } else if (this.type === 'video') {
                this.icon = 'fa-file-video-o';
            }
        }

        this.width           = item.width;
        this.height          = item.height;
        this.preview         = item.preview;
        this.extension       = item.extension;
        this.created_at      = item.created_at;
        this.updated_at      = item.updated_at;
        this.updatedName     = item.name;
        this.attachmentCount = item.attachment_count ? item.attachment_count : 0;

        this.getDate = function () {
            return (this.created_at !== this.updated_at) ? this.updated_at : this.created_at;
        }
    };

    // Disable Dropzone autoDiscover
    Dropzone.autoDiscover = false;

    /*
     * Angular Dropzone
     */
    (function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            define(['angular', 'dropzone'], factory);
        } else {
            factory(root.angular, root.Dropzone);
        }
    }(this, function (angular, Dropzone) {

        var dropzone = angular.module('ngDropzone', []);

        dropzone.directive('ngDropzone', function () {
            return {
                restrict:   'AE',
                template:   '<div ng-transclude></div>',
                transclude: true,
                scope:      {
                    dropzone:       '=',
                    dropzoneConfig: '=',
                    eventHandlers:  '='
                },
                link:       function (scope, element, attrs, ctrls) {
                    try {
                        Dropzone
                    }
                    catch (error) {
                        throw new Error('Dropzone.js not loaded.');
                    }

                    var dropzone = new Dropzone(element[0], scope.dropzoneConfig);

                    if (scope.eventHandlers) {
                        Object.keys(scope.eventHandlers).forEach(function (eventName) {
                            dropzone.on(eventName, scope.eventHandlers[eventName]);
                        });
                    }

                    scope.dropzone = dropzone;
                }
            };
        });

        return dropzone;

    }));

    /*
     * Media picker
     */
    var mediapicker = angular.module('mediapicker', ['ngDropzone']);

    /*
     * Mediapicker Controller
     */
    mediapicker.controller('MediapickerCtrl', function ($scope, $rootScope, $element, MediaService, MediaRepository) {
        $scope.loading = 0;
        $scope.details = null;
        $scope.search  = {query: '', loading: 0, page: 1};

        $rootScope.loading = 0;

        $rootScope.api = {
            csrf:    '',
            params:  {
                types:           '',
                owner_id:        '',
                transformations: '',
            },
            baseUrl: window.API.URL + '/media'
        };

        // Recieve settings from the element
        $element.on('medialibrary.settings', function (e, settings) {
            $scope.$apply(function () {
                $rootScope.api.csrf = settings.csrf;
            });
        });

        // Fires when the mediapicker view is shown to the user
        // TODO: Only do this when the upload tab is opened!
        $element.on('medialibrary.open', function () {
            var newType = $element.data('types') ? $element.data('types') : '';
            if ($rootScope.api.params.types !== newType) {
                $rootScope.api.params.types = newType;
                $scope.details              = null;
                $rootScope.$broadcast('MediaPicker::items.clear');
            }

            var newTransformations = $element.data('transformations') ? $element.data('transformations') : '';
            if ($rootScope.api.params.transformations !== newTransformations) {
                $rootScope.api.params.transformations = newTransformations;
                $scope.details                        = null;
                $rootScope.$broadcast('MediaPicker::items.clear');
            }

            var newOwner = $element.data('owner') ? $element.data('owner') : '';
            if ($rootScope.api.params.owner_id !== newOwner) {
                $rootScope.api.params.owner_id = newOwner;
                $scope.details                 = null;
                $rootScope.$broadcast('MediaPicker::items.clear');
            }

            $rootScope.$broadcast('MediaPicker::opened');

            // Load the dropzone
            $scope.dropzoneload = function () {
                return 'LOADDROPZONE';
            }

        });

        // Is the user allowed to delete items
        $scope.canDelete = function (attachmentCount) {
            return attachmentCount === 0 && $element.data('canDelete') === true;
        };

        // Is the user allowed to update items
        $scope.canUpdate = function () {
            return $element.data('canUpdate') === true;
        };

        // Listen to the respository for a item.select event
        $rootScope.$on('MediaRepository::item.selected', function (e, item) {
            $scope.details = item;
        });

        // Search
        $scope.$watch('search.query', function (query, old) {
            if (query !== old) {
                if (!query.length) {
                    MediaRepository.isSearch(false);
                } else {
                    MediaRepository.isSearch(true);
                    $scope.search.page = 1;
                    $scope.search.loading += 1;

                    MediaService.search(query, $scope.search.page)
                        .success(function (response) {
                            MediaRepository.addAll(response.data);

                            $scope.search.page += 1;
                            $scope.search.loading -= 1;
                        });
                }
            }
        });

        // Search MediaItems
        $rootScope.$on('MediaPicker::search::nextPage', function () {
            $rootScope.loading += 1;

            MediaService.search($scope.search.query, $scope.search.page)
                .success(function (response) {
                    MediaRepository.addAll(response.data);

                    $scope.search.page += 1;
                    $rootScope.loading -= 1;
                });
        });

        // Handel scrolling to next page!
        var scrollable = _.debounce(function () {
            var last = jQuery('.mediaLibrary-images').find('div.wrapper:last-child');

            if (last.length && last.attr('data-detected') === undefined && FietsZaken.Util.isScrolledIntoView(last)) {
                last.attr('data-detected', true);

                if ($scope.search.query.length) {
                    $rootScope.$broadcast('MediaPicker::search::nextPage');
                } else {
                    $rootScope.$broadcast('MediaPicker::nextPage');
                }
            }
        }, 300);

        jQuery('.tab-content-wrapper').scroll(scrollable);

        // Persists the MediaItem in the database
        $scope.update = function (id) {
            $scope.loading += 1;

            var item = MediaRepository.byId(id);

            item.name = $scope.details.updatedName;

            MediaService.update(item)
                .success(function (response) {
                    MediaRepository.update(response.data);

                    $scope.loading -= 1;
                });
        };

        // Delete the MediaItem from the database
        $scope.delete = function (id) {
            swal({
                title:              window.mediapicker_i18n.confirmation.delete.title,
                text:               window.mediapicker_i18n.confirmation.delete.text,
                type:               "warning",
                showCancelButton:   true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText:  window.mediapicker_i18n.confirmation.delete.confirm,
                cancelButtonText:   window.mediapicker_i18n.confirmation.delete.cancel,
                closeOnConfirm:     true
            }, function () {
                $scope.loading += 1;

                MediaService.delete(MediaRepository.byId(id))
                    .success(function () {
                        MediaRepository.delete(id);

                        $scope.loading -= 1;
                    });

                MediaRepository.select(null);
            });
        };

        // Check if the scope is loading
        $scope.isLoading = function () {
            return $scope.loading > 0;
        };

        // Check if the search scope is loading
        $scope.isSearchLoading = function () {
            return $scope.search.loading > 0;
        };

        // User is finished and has selected a file
        $scope.select = function () {
            if ($scope.details !== null) {
                $element.trigger('mediapicker.selected', [
                    [
                        MediaRepository.selected()
                    ]
                ]);
            }
        };

        // User cancelled the picking of a file
        $scope.cancel = function () {
            $element.trigger('mediapicker.cancelled');
        };
    });

    /*
     * Mediapicker Browser Controller
     */
    mediapicker.controller('MediapickerBrowserCtrl', function ($scope, $rootScope, MediaService, MediaRepository) {
        $scope.page       = 1;
        $scope.selected   = null;
        $scope.mediaItems = [];

        // Load the MediaItems when the MediaPicker is opened
        $rootScope.$on('MediaPicker::opened', function () {
            $rootScope.loading += 1;

            MediaService.get().success(function (data) {
                MediaRepository.addAll(data.data);

                $scope.page += 1;
                $rootScope.loading -= 1;
            }).error(function () {
                $rootScope.loading -= 1;
            });
        });

        // Load the next page of MediaItems
        $rootScope.$on('MediaPicker::nextPage', function () {
            $rootScope.loading += 1;

            MediaService.get({'page': $scope.page}).success(function (data) {
                MediaRepository.addAll(data.data);

                $scope.page += 1;
                $rootScope.loading -= 1;
            }).error(function () {
                $rootScope.loading -= 1;
            });
        });

        // Listen to the respository for a item.select event
        $rootScope.$on('MediaRepository::item.selected', function (e, item) {
            $scope.selected = (item instanceof MediaItem) ? item.id : null;
        });

        // Listen to the repository changing it's contents
        $rootScope.$on('MediaRepository::items.changed', function () {
            $scope.mediaItems = MediaRepository.all();
        });

        // Listen to the repository clear contents
        $rootScope.$on('MediaPicker::items.clear', function () {
            MediaRepository.resetItems();
            $scope.selected = null;
            $scope.page     = 0;
        });

        // Select a item from the MediaItem list
        $scope.select = function (item, event) {
            MediaRepository.select(item.id);
        };

        // Check if an item is currently selected
        $scope.isSelected = function (item) {
            return ($scope.selected === item.id);
        };

        // Check if the scope is loading
        $scope.isLoading = function () {
            return $rootScope.loading > 0;
        };
    });

    /*
     * Mediapicker Dropzone Controller
     */
    mediapicker.controller('MediapickerDropzoneCtrl', function ($scope, $rootScope, MediaRepository, $rootElement) {
        $scope.dropzoneConfig = {
            maxFiles:           2,
            parallelUploads:    3,
            maxFileSize:        10,
            dictDefaultMessage: '<div class="text-master heading fs-18">' +
                                window.mediapicker_i18n.dropzone.placeholder +
                                '</div>',
            url:                $rootScope.api.baseUrl + '/upload?' + jQuery.param($rootScope.api.params, true),
            headers:            {
                'X-CSRF-TOKEN': $rootScope.api.csrf
            },
            eventHandlers:      {
                success: function (file, response) {
                    $scope.$apply(function () {
                        MediaRepository.add(response.data);

                        MediaRepository.select(response.data.id);
                    });
                }
            }
        };
    });

    /*
     * Media Factory
     */
    mediapicker.service('MediaService', function ($http, $rootScope) {
        this.get = function (options) {
            var prms = {};
            var opts = jQuery.extend(true, prms, $rootScope.api.params, options);
            return $http({
                method: 'GET',
                url:    $rootScope.api.baseUrl,
                params: prms
            });
        };

        this.search = function (query, page) {
            var prms = {};
            var opts = jQuery.extend(true, prms, $rootScope.api.params, {
                q:    query,
                page: page
            });
            return $http({
                method: 'GET',
                url:    $rootScope.api.baseUrl,
                params: prms
            });
        };

        this.update = function (item) {
            return $http({
                method: 'PUT',
                data:   item,
                url:    $rootScope.api.baseUrl + '/' + item.id
            });
        };

        this.delete = function (item) {
            var prms = {};
            var opts = jQuery.extend(true, prms, $rootScope.api.params, {});
            return $http({
                method: 'DELETE',
                params: prms,
                url:    $rootScope.api.baseUrl + '/' + item.id
            });
        };
    });

    mediapicker.factory('MediaRepository', function ($rootScope) {
        var factory = {_items: [], _itemsCache: [], _selected: null, _isSearching: false};

        function add(item) {
            if (!(item instanceof MediaItem)) {
                item = new MediaItem(item);
            }

            var isFound = false,
                index   = 0,
                current;

            for (; current = factory._items[index++];) {
                if (item.id === current.id) {
                    isFound = true;
                }
            }

            if (isFound === false) {
                factory._items.push(item);
            }
        }

        function indexById(id) {
            var a,
                i = 0;

            for (; a = factory._items[i++];) {
                if (a.id === id) {
                    return i - 1
                }
            }
        }

        factory.isSearch = function (isSearch) {
            if (isSearch === true) {
                if (factory._isSearching === false) {
                    factory._itemsCache = factory._items;
                }
                factory._items = [];
            } else {
                factory._items = factory._itemsCache;
            }
            factory._isSearching = isSearch;

            $rootScope.$broadcast('MediaRepository::items.changed');
        };

        factory.resetItems = function () {
            factory._items = [];

            $rootScope.$broadcast('MediaRepository::items.changed');
        };

        factory.byId = function (id) {
            return factory._items[indexById(id)];
        };

        factory.all = function () {
            return factory._items;
        };

        factory.add = function (item) {
            add(item);

            $rootScope.$broadcast('MediaRepository::items.changed');
        };

        factory.update = function (item) {
            angular.extend(factory._items[indexById(item.id)], item);

            $rootScope.$broadcast('MediaRepository::items.changed');
        };

        factory.delete = function (id) {
            factory._items.splice(indexById(id), 1);

            $rootScope.$broadcast('MediaRepository::items.changed');
        };

        factory.addAll = function (items) {
            angular.forEach(items, function (item) {
                add(item);
            });

            $rootScope.$broadcast('MediaRepository::items.changed');
        };

        factory.select = function (id) {
            var item = null;

            if (id !== undefined && id !== null) {
                var current = factory.selected();

                item = (current instanceof MediaItem && current.id === id) ? null : factory.byId(id);

                factory._selected = item;
            }

            $rootScope.$broadcast('MediaRepository::item.selected', item);
        };

        factory.selected = function () {
            return factory._selected;
        };

        return factory;
    });

    /*
     * Dropzone Directive
     */
    mediapicker.directive('divdropzone', function () {
        return {
            restrict: "E",
            template: '<div ng-controller="MediapickerDropzoneCtrl">' +
                      '<form ng-dropzone dropzone="dropzone" dropzone-config="dropzoneConfig" event-handlers="dropzoneConfig.eventHandlers" class="dropzone no-margin m-t-10">' +
                      '<div class="fallback">' +
                      '<input name="file" type="file" multiple />' +
                      '</div>' +
                      '</form>' +
                      '</div>',
            link:     function (scope, element) {
                element.on('$destroy', function () {
                    scope.$destroy();
                });
            }
        }
    });

})();
