(function (FietsZaken) {

    FietsZaken.Picker = {

        modal: jQuery('#CipeMotionMediaPickerModal'),

        pick: function (e) {
            e.preventDefault();

            var elem            = jQuery(this),
                picker          = FietsZaken.Picker.modal,
                pickerId        = elem.data('picker'),
                types           = elem.data('picker-types') ? elem.data('picker-types') : '',
                transformations = elem.data('picker-transformations') ? elem.data('picker-transformations') : '',
                owner           = elem.data('picker-owner') ? elem.data('picker-owner') : '',
                clear           = jQuery('[data-picker-clear="' + pickerId + '"]'),
                preview         = jQuery('[data-picker-preview="' + pickerId + '"]'),
                input           = jQuery('[data-picker-value="' + pickerId + '"]'),
                setTitle        = (input.data('picker-set-title') ? jQuery(input.data('picker-set-title')) : false);

            picker.mediapicker({'types': types, 'transformations': transformations, 'owner': owner}).pick(function (item) {
                if (!types.length || jQuery.inArray(item[0].type, types.split(',')) !== -1) {
                    input.val(item[0].id).trigger('input');

                    if (setTitle) {
                        if (setTitle.is('input,textarea') && !setTitle.val().length) {
                            setTitle.val(item[0].name).trigger('input');
                        } else {
                            setTitle.text(item[0].name);
                        }
                    }

                    preview.attr('src', item[0].url).removeClass('hidden');

                    clear.removeClass('hidden');
                } else {
                    var typesReplace = '',
                        typesArr     = types.split(',');
                    jQuery.each(typesArr, function (index, type) {
                        if (index > 0) {
                            if ((index + 1) !== typesArr.length) {
                                typesReplace += ', ';
                            } else {
                                typesReplace += FietsZaken.i18n.strings.msg_or;
                            }
                        }

                        if (window.mediapicker_i18n.file_types[type]) {
                            typesReplace += window.mediapicker_i18n.file_types[type].name;
                        } else {
                            typesReplace += type;
                        }
                    });

                    swal({
                        title: window.mediapicker_i18n.picker.not_allowed_type.title,
                        text:  window.mediapicker_i18n.picker.not_allowed_type.text.replace('%TYPES%', typesReplace.toLowerCase()),
                        type:  "info"
                    });
                }
            });
        },

        clear: function (e) {
            e.preventDefault();

            var elem     = jQuery(this),
                pickerId = elem.data('picker-clear'),
                preview  = jQuery('[data-picker-preview="' + pickerId + '"]'),
                input    = jQuery('[data-picker-value="' + pickerId + '"]');

            input.val('').trigger('input');

            preview.attr('src', '#').addClass('hidden');

            elem.addClass('hidden');
        },

        init: function () {
            if (this.modal.length > 0) {

                this.modal.mediapicker({
                    api:         window.HOME + '/media/api',
                    csrf:        window.CSRF,
                    multiSelect: false
                });

                jQuery('body')
                    .on('click', '[data-picker]', this.pick)
                    .on('click', '[data-picker-clear]', this.clear);
            }
        }

    };

    FietsZaken.registerModule(jQuery.proxy(FietsZaken.Picker.init, FietsZaken.Picker));

})(window.FietsZaken);
