(function (FietsZaken) {

    FietsZaken.Nestable = {

        nestables: {

            default: {
                xhr: null
            },

            services: {
                maxDepth: 1,

                events: {
                    change: function (elem, options) {
                        if (options.xhr !== null) {
                            options.xhr.abort();
                        }

                        options.xhr = jQuery.ajax({
                            url:    FietsZaken.Util.getAPIUrl('/nestable/services'),
                            method: 'POST',
                            data:   {
                                _token: window.CSRF,
                                type:   'TYPE_' + jQuery(this).data('nestable-type'),
                                items:  elem.nestable('serialize')
                            }
                        });
                    }
                }
            },

            marks: {
                maxDepth: 1,

                events: {
                    change: function (elem, options) {
                        if (options.xhr !== null) {
                            options.xhr.abort();
                        }

                        options.xhr = jQuery.ajax({
                            url:    FietsZaken.Util.getAPIUrl('/nestable/marks'),
                            method: 'POST',
                            data:   {
                                _token: window.CSRF,
                                items:  elem.nestable('serialize')
                            }
                        });
                    }
                }
            },

            partners: {
                maxDepth: 1,

                events: {
                    change: function (elem, options) {
                        if (options.xhr !== null) {
                            options.xhr.abort();
                        }

                        options.xhr = jQuery.ajax({
                            url:    FietsZaken.Util.getAPIUrl('/nestable/partners'),
                            method: 'POST',
                            data:   {
                                _token: window.CSRF,
                                items:  elem.nestable('serialize')
                            }
                        });
                    }
                }
            },

            menu_main: {
                maxDepth: 2,

                events: {
                    change: function (elem, options) {
                        if (options.xhr !== null) {
                            options.xhr.abort();
                        }

                        options.xhr = jQuery.ajax({
                            url:    FietsZaken.Util.getAPIUrl('/nestable/menu'),
                            method: 'POST',
                            data:   {
                                _token: window.CSRF,
                                items:  elem.nestable('serialize'),
                                type:   'main'
                            }
                        });
                    }
                }
            },

            menu_footer: {
                maxDepth: 2,

                events: {
                    change: function (elem, options) {
                        if (options.xhr !== null) {
                            options.xhr.abort();
                        }

                        options.xhr = jQuery.ajax({
                            url:    FietsZaken.Util.getAPIUrl('/nestable/menu'),
                            method: 'POST',
                            data:   {
                                _token: window.CSRF,
                                items:  elem.nestable('serialize'),
                                type:   'footer'
                            }
                        });
                    }
                }
            },

            menu_footer_bar: {
                maxDepth: 1,

                events: {
                    change: function (elem, options) {
                        if (options.xhr !== null) {
                            options.xhr.abort();
                        }

                        options.xhr = jQuery.ajax({
                            url:    FietsZaken.Util.getAPIUrl('/nestable/menu'),
                            method: 'POST',
                            data:   {
                                _token: window.CSRF,
                                items:  elem.nestable('serialize'),
                                type:   'footer_bar'
                            }
                        });
                    }
                }
            },
        },

        init: function () {
            for (var nestable in this.nestables) {
                if (this.nestables.hasOwnProperty(nestable)) {
                    var value    = this.nestables[nestable],
                        selector = '.nestable-' + nestable;

                    if (nestable === 'default') {
                        selector = '.nestable';
                    }

                    jQuery(selector).each(function (i, e) {
                        var elem = jQuery(e),
                            opts = jQuery.extend(true, {}, FietsZaken.Nestable.nestables.default, value);

                        elem.nestable(opts);

                        jQuery.each(opts.events || [], function (event, func) {
                            if (jQuery.type(func) === 'function') {
                                elem.on(event, jQuery.proxy(func, FietsZaken.Nestable, elem, opts));
                            }
                        });
                    });
                }
            }
        }

    };

    FietsZaken.registerModule(jQuery.proxy(FietsZaken.Nestable.init, FietsZaken.Nestable));

})(window.FietsZaken);
