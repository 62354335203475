(function () {

    // Init FietsZaken and all modules
    angular.module('fiets-zaken', [

        // Modules
        'mediapicker'

    ]);

})();
