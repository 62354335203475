(function (FietsZaken) {

    FietsZaken.Elements = {

        slugify: function (element) {
            var target = jQuery(element.data('slugify-target'));

            target.data('fiets-zaken-changed', false);

            target.on('change keyup', function () {
                target.data('fiets-zaken-changed', target.val() != '');
            });

            element.on('change keyup', function () {
                if (target.data('fiets-zaken-changed') === false) {
                    target.val(FietsZaken.Util.slugify(element.val())).trigger('input');
                }
            });
        },

        clearInput: function () {
            jQuery(jQuery(this).data('clear-input')).val('');
        },

        getFormAdresCoords: function () {
            var elem         = jQuery(this).closest('.address-inputs'),
                stopFunction = false,
                address      = [];

            jQuery.each(['#street', '#nr', '#zipcode', '#city_id'], function (_, query) {
                var inputElem = elem.find(':input' + query);

                if (inputElem.val() !== undefined && inputElem.val().length) {
                    if (inputElem.attr('name') === 'city_id' || inputElem.attr('name') === 'province_id') {//|| input.name === 'country_id'
                        address.push(elem.find('[name="' + inputElem.attr('name') + '"][value="' + inputElem.val() + '"]').parent().find('.selectize-control [data-value="' + inputElem.val() + '"]').attr('data-name'));

                        return;
                    }

                    address.push(inputElem.attr('name') === 'zipcode' ? inputElem.val().replace('+', '') : inputElem.val());
                } else {
                    stopFunction = true;
                }
            });

            if (stopFunction) {
                return;
            }

            FietsZaken.Util.getAddressData(address.join('+').replace(/ /g, '+')).done(function (data) {
                if (data.status === 'OK') {
                    elem.find('#lat').val(data.results[0]['geometry']['location']['lat']);
                    elem.find('#lng').val(data.results[0]['geometry']['location']['lng']);
                }
            }, 'json');
        },

        checkingGeoLocation: false,

        getGeoLocation: function (position, elem) {
            var location = "",
                country  = "",
                valElem  = jQuery(elem.data('geo-location'));

            jQuery.ajax({
                type:     'GET',
                dataType: "json",
                url:      "//maps.googleapis.com/maps/api/geocode/json?latlng=" + position.coords.latitude + "," + position.coords.longitude + "&sensor=false&key=" + window.GOOGLE_KEY,
                data:     {},
                success:  function (data) {
                    valElem.html(data);

                    jQuery.each(data['results'], function (i, val) {
                        if (val['formatted_address'] !== undefined && val['formatted_address'].length) {
                            valElem.val(val['formatted_address']);

                            return false;
                        }

                        jQuery.each(val['address_components'], function (i, val) {
                            if (val['types'] == "locality,political") {
                                if (val['long_name'] != "") {
                                    location += val['long_name'];
                                }
                            }
                            if (val['types'] == "administrative_area_level_1,political") {
                                if (val['short_name'] != "") {
                                    location += ', ' + val['short_name'];
                                }
                            }
                            if (val['types'] == "country,political") {
                                if (val['long_name'] != "") {
                                    location += ', ' + val['long_name'];
                                    country = val['short_name'];
                                } else {
                                    valElem.val("unknown");
                                }
                                if (val['short_name'] != "") {
                                    country = val['short_name'];
                                }

                                valElem.val(location);

                                return false;
                            }

                        });

                        return false;
                    });
                }
            }).always(function () {
                elem.removeClass('geo-location-loading');

                FietsZaken.Elements.checkingGeoLocation = false;
            });
        },

        formPrePost: function (form) {
            form.addClass('prepost-loading');

            form.find('.help-block.error-block').remove();
            form.find('.form-group.has-error').removeClass('has-error');

            jQuery.ajax({
                type:     'POST',
                dataType: "json",
                url:      form.attr('action'),
                data:     form.serialize(),
                success:  function (data) {
                    form.closest('.modal').modal('hide');

                    swal(window.i18n.prepost.succes.title, data.text, "success");
                },
                error:    function (data) {
                    if (data.status === 422) {
                        jQuery.each(data.responseJSON, function (field, msg) {
                            form.find('[name="' + field + '"]').closest('.form-group').addClass('has-error').append('<span class="help-block error-block">' + msg[0] + '</span>');
                        });
                    }
                }
            }).always(function () {
                form.removeClass('prepost-loading');
            });
        },

        init: function () {

            jQuery('[data-clear-input]').click(FietsZaken.Elements.clearInput);

            jQuery('[data-geo-location]').click(function () {
                var elem = jQuery(this);

                if (elem.attr('aria-describedby') !== undefined) {
                    elem.popover('destroy');

                    return;
                }

                if (!"geolocation" in navigator || !navigator.geolocation) {
                    elem.addClass('geo-location-not-supported').popover({
                        content:   window.i18n.geolocation.msg_not_supported_by_browser,
                        placement: 'bottom',
                        trigger:   'manual',
                        container: 'body'
                    }).popover('show');
                } else if (!FietsZaken.Elements.checkingGeoLocation) {
                    FietsZaken.Elements.checkingGeoLocation = true;

                    elem.addClass('geo-location-loading');

                    navigator.geolocation.getCurrentPosition(function (position) {
                        FietsZaken.Elements.getGeoLocation(position, elem);
                    }, function (error) {
                        elem.popover({
                            content:   error.code === error.PERMISSION_DENIED ? window.i18n.geolocation.msg_error_permission_denied : window.i18n.geolocation.msg_error_try_again,
                            placement: 'bottom',
                            trigger:   'manual',
                            container: 'body'
                        }).popover('show');

                        elem.removeClass('geo-location-loading');

                        FietsZaken.Elements.checkingGeoLocation = false;
                    }, {
                        timeout:            5000,
                        maximumAge:         0,
                        enableHighAccuracy: true
                    });
                }

                elem.on('show.bs.popover', function () {
                    jQuery('html').on('click.geoLocationPopover', function () {
                        elem.popover('destroy');
                    });
                }).on('hidden.bs.popover', function () {
                    jQuery('html').off('click.geoLocationPopover');
                });
            });

            jQuery('input[data-slugify="true"][data-slugify-target]').each(function () {
                FietsZaken.Elements.slugify(jQuery(this));
            });

            jQuery('.address-inputs :input:not(#lng, #lat)').on('change', FietsZaken.Elements.getFormAdresCoords);

            jQuery('[data-show-click]').on('click', function () {
                var elem   = jQuery(this),
                    type   = elem.data('show-type'),
                    output = elem.data('show-click');

                if (type === 'phone') {
                    output = '<a href="tel:' + output.replace(/[^0-9]/g, '') + '" target="_blank">' + output + '</a>';
                }

                elem.html(output);
            });
        }
    };

    FietsZaken.registerModule(jQuery.proxy(FietsZaken.Elements.init, FietsZaken.Elements));

})(window.FietsZaken);


