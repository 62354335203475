(function (FietsZaken) {

    FietsZaken.DataTable = {

        datatables: {

            default: {
                options: {
                    responsive: true,

                    pageLength:   10,
                    searching:    true,
                    lengthChange: false,
                    stateSave:    true,
                    order:        [[0, 'desc']],

                    oLanguage: window.i18n.datatable,

                    processing: true,
                    serverSide: true,
                    ajax:       {
                        url:  FietsZaken.Util.getAPIUrl('/datatable'),
                        data: {
                            type: 'default'
                        }
                    },
                    columns:    [{}]
                }
            },

            products: {
                options: {
                    ajax: {
                        data: {
                            type: 'products'
                        }
                    },

                    columns: [{
                        data:  "name",
                        title: window.i18n.datatable.products.columns.name.title
                    }, {
                        data:  "type_name",
                        title: window.i18n.datatable.products.columns.type.title
                    }, {
                        data:  "is_active_label",
                        title: window.i18n.datatable.products.columns.is_active.title
                    }, {
                        data:  "price_format",
                        title: window.i18n.datatable.products.columns.price_format.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      200,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            reviews: {
                options: {
                    ajax: {
                        data: {
                            type: 'reviews'
                        }
                    },

                    columns: [{
                        data:  "company_name",
                        title: window.i18n.datatable.reviews.columns.company.title
                    }, {
                        data:  "name",
                        title: window.i18n.datatable.reviews.columns.name.title
                    }, {
                        data:  "email",
                        title: window.i18n.datatable.reviews.columns.email.title
                    }, {
                        data:  "created_at_format",
                        title: window.i18n.datatable.reviews.columns.created_at.title
                    }, {
                        data:  "is_accepted_label",
                        title: window.i18n.datatable.reviews.columns.is_accepted.title
                    }, {
                        data:  "is_verified_label",
                        title: window.i18n.datatable.reviews.columns.is_verified.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      200,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            places: {
                options: {
                    ajax: {
                        data: {
                            type: 'places'
                        }
                    },

                    columns: [{
                        data:  "name",
                        title: window.i18n.datatable.places.columns.name.title
                    }, {
                        data:  "type_name",
                        title: window.i18n.datatable.places.columns.type.title
                    }, {
                        data:  "is_active_label",
                        title: window.i18n.datatable.places.columns.is_active.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      200,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            companies: {
                options: {
                    ajax: {
                        data: {
                            type: 'companies'
                        }
                    },

                    columns: [{
                        data:  "name",
                        title: window.i18n.datatable.companies.columns.name.title
                    }, {
                        data:  "city_name_label",
                        title: window.i18n.datatable.companies.columns.city.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            company_reviews: {
                options: {
                    ajax: {
                        data: {
                            type: 'companyReviews'
                        }
                    },

                    columns: [{
                        data:  "name",
                        title: window.i18n.datatable.reviews.columns.name.title
                    }, {
                        data:  "email",
                        title: window.i18n.datatable.reviews.columns.email.title
                    }, {
                        data:  "created_at_format",
                        title: window.i18n.datatable.reviews.columns.created_at.title
                    }, {
                        data:  "is_accepted_label",
                        title: window.i18n.datatable.reviews.columns.is_accepted.title
                    }, {
                        data:  "is_verified_label",
                        title: window.i18n.datatable.reviews.columns.is_verified.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      200,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            company_current_products: {
                options: {
                    ajax: {
                        data: {
                            type: 'companyCurrentProducts'
                        }
                    },

                    columns: [{
                        data:  "name",
                        title: window.i18n.datatable.company_current_products.columns.product.title
                    }, {
                        data:  "status_label",
                        title: window.i18n.datatable.company_current_products.columns.status.title
                    }, {
                        data:  "start_at_format",
                        title: window.i18n.datatable.company_current_products.columns.start_at.title
                    }, {
                        data:  "end_at_format",
                        title: window.i18n.datatable.company_current_products.columns.end_at.title
                    }]
                }
            },

            company_orders: {
                options: {
                    ajax: {
                        data: {
                            type: 'companyOrders'
                        }
                    },

                    columns: [{
                        data:  "invoice_nr",
                        title: window.i18n.datatable.company_orders.columns.invoice_nr.title
                    }, {
                        data:  "status_name",
                        title: window.i18n.datatable.company_orders.columns.status.title
                    }, {
                        data:  "total_format",
                        title: window.i18n.datatable.company_orders.columns.price.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            company_users: {
                options: {
                    ajax: {
                        data: {
                            type: 'companyUsers'
                        }
                    },

                    columns: [{
                        data:  "firstname",
                        title: window.i18n.datatable.company_users.columns.firstname.title
                    }, {
                        data:  "lastname",
                        title: window.i18n.datatable.company_users.columns.lastname.title
                    }, {
                        data:  "email",
                        title: window.i18n.datatable.company_users.columns.email.title
                    }]
                }
            },

            forms: {
                options: {
                    ajax: {
                        data: {
                            type: 'forms'
                        }
                    },

                    order: [[3, 'desc']],

                    columns: [{
                        data:       "name",
                        orderable:  false,
                        searchable: false,
                        title:      window.i18n.datatable.forms.columns.name.title
                    }, {
                        data:  "type_name",
                        title: window.i18n.datatable.forms.columns.type.title
                    }, {
                        data:  "user_name",
                        title: window.i18n.datatable.forms.columns.user_name.title
                    }, {
                        data:  "company_name",
                        title: window.i18n.datatable.forms.columns.company_name.title
                    }, {
                        data:  "created_at_format",
                        title: window.i18n.datatable.forms.columns.created_at.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            orders: {
                options: {
                    ajax: {
                        data: {
                            type: 'orders'
                        }
                    },

                    columns: [{
                        data:  "invoice_nr",
                        title: window.i18n.datatable.orders.columns.invoice_nr.title
                    }, {
                        data:  "company_name",
                        title: window.i18n.datatable.orders.columns.company_name.title
                    }, {
                        data:  "user_name",
                        title: window.i18n.datatable.orders.columns.user_name.title
                    }, {
                        data:  "status_name",
                        title: window.i18n.datatable.orders.columns.status.title
                    }, {
                        data:  "total_format",
                        title: window.i18n.datatable.orders.columns.price.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            user_orders: {
                options: {
                    ajax: {
                        data: {
                            type: 'userOrders'
                        }
                    },

                    columns: [{
                        data:  "invoice_nr",
                        title: window.i18n.datatable.user_orders.columns.invoice_nr.title
                    }, {
                        data:  "company_name",
                        title: window.i18n.datatable.orders.columns.company_name.title
                    }, {
                        data:  "status_name",
                        title: window.i18n.datatable.user_orders.columns.status.title
                    }, {
                        data:  "total_format",
                        title: window.i18n.datatable.user_orders.columns.price.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            services: {
                options: {
                    ajax: {
                        data: {
                            type: 'services'
                        }
                    },

                    columns: [{
                        data:  "name",
                        title: window.i18n.datatable.services.columns.name.title
                    }, {
                        data:  "type_name",
                        title: window.i18n.datatable.services.columns.type.title
                    }, {
                        data:  "text_allowed_label",
                        title: window.i18n.datatable.services.columns.text_allowed.title
                    }, {
                        data:  "is_selectable_homepage_label",
                        title: window.i18n.datatable.services.columns.is_selectable_homepage.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            menus: {
                options: {
                    ajax: {
                        data: {
                            type: 'menus'
                        }
                    },

                    columns: [{
                        data:  "title",
                        title: window.i18n.datatable.menus.columns.title.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            pages: {
                options: {
                    ajax: {
                        data: {
                            type: 'pages'
                        }
                    },

                    columns: [{
                        data:  "title",
                        title: window.i18n.datatable.pages.columns.title.title
                    }, {
                        data:  "type_name",
                        title: window.i18n.datatable.pages.columns.type_name.title
                    }, {
                        data:  "status_name",
                        title: window.i18n.datatable.pages.columns.status.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            news: {
                options: {
                    ajax: {
                        data: {
                            type: 'news'
                        }
                    },

                    columns: [{
                        data:  "title",
                        title: window.i18n.datatable.news.columns.title.title
                    }, {
                        data:  "status_name",
                        title: window.i18n.datatable.news.columns.status.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            users: {
                options: {
                    ajax: {
                        data: {
                            type: 'users'
                        }
                    },

                    columns: [{
                        data:  "firstname",
                        title: window.i18n.datatable.users.columns.firstname.title
                    }, {
                        data:  "lastname",
                        title: window.i18n.datatable.users.columns.lastname.title
                    }, {
                        data:  "email",
                        title: window.i18n.datatable.users.columns.email.title
                    }, {
                        data:  "role_name",
                        title: window.i18n.datatable.users.columns.role.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            imports: {
                options: {
                    ajax: {
                        data: {
                            type: 'imports'
                        }
                    },

                    columns: [{
                        data:  "created_at_format",
                        title: window.i18n.datatable.imports.columns.created_at.title
                    }, {
                        data:  "type",
                        title: window.i18n.datatable.imports.columns.type.title
                    }, {
                        data:  "state",
                        title: window.i18n.datatable.imports.columns.state.title
                    }, {
                        data:       "new_count",
                        orderable:  false,
                        searchable: false,
                        title:      window.i18n.datatable.imports.columns.new_count.title
                    }, {
                        data:       "old_count",
                        orderable:  false,
                        searchable: false,
                        title:      window.i18n.datatable.imports.columns.old_count.title
                    }]
                }
            }

        },

        init: function () {
            for (var datatable in this.datatables) {
                if (this.datatables.hasOwnProperty(datatable)) {
                    var value    = this.datatables[datatable],
                        selector = '.datatable-' + datatable;

                    if (datatable === 'default') {
                        selector = '.datatable';
                    }

                    jQuery(selector).each(function (i, e) {
                        var elem     = jQuery(e),
                            opts     = {},
                            settings = elem.data('datatable-settings'),
                            data     = elem.data('datatable-data'),
                            options  = jQuery.extend(true, opts, FietsZaken.DataTable.datatables.default.options, value.options);

                        if (settings) {
                            jQuery.extend(opts, settings);
                        }

                        if (data) {
                            jQuery.extend(opts.ajax.data, data);
                        }

                        var table = elem.DataTable(opts);
                        table.on('draw', function () {
                            elem.find('[data-confirm]').click(FietsZaken.Util.confirmClick);
                        }).on('error.dt', function (e, settings, techNote, message) {
                            if (window.SENTRY.ENABLED) {
                                Raven.captureException(e);
                            }

                            console.log('An error has been reported by DataTables: ', message);
                        });
                    });
                }
            }
        }
    };

    FietsZaken.registerModule(jQuery.proxy(FietsZaken.DataTable.init, FietsZaken.DataTable));

})(window.FietsZaken);
